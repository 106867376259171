.navbarMain{  
    position: fixed;
    overflow-x: hidden;
    top: 0;
    /* width: 100%; */
    z-index: 2; 
    height: 100px;   
    transition: 300ms;
    display:flex;
    align-items: center; 
    -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);  
}
.navbarMain--hidden{
    transform: translateY(-100px);
    transition: 2000ms; 
}
ul{
  list-style: none;
}
.titleSize{
  font-size: 200px;
}
.whyUsText{
  font-size: 300px;
 }
.button{
  padding:10px 20px;
  cursor: pointer;
  font-weight: 600;
  transition: 1s ;
  border: 1px solid;
  border-radius: 50px;
}
.buttonSpan{
  margin-left: 0px;
  transition: 1s ; 
}
.button:hover{
  padding:8px 30px; 
  border: 1px solid black;
  border-radius: 50px;
  transition:1s; 
  color: black;
}
.button:hover .buttonSpan{
  margin-left: 30px;
  transition
  
  : 1s ;  
}
.ourBtnarow{
  margin-left: 0px;
  transition: .5s ;  

}
.ourBtnarowMain:hover .ourBtnarow{
  margin-left: 10px;
  transition: .5s ;  

}

.buttonBlack{
  padding:10px 20px;
  cursor: pointer;
  font-weight: 600;
  transition: 1s ;
  border: 1px solid;
  border-radius: 50px;
}
.buttonBlackSpan{
  margin-left: 0px;
  transition: 1s ; 
}
.buttonBlack:hover{
  padding:8px 30px; 
  border: 1px solid white;
  border-radius: 50px;
  transition:1s; 
  color: white;
}
.buttonBlack:hover .buttonBlackSpan{
  margin-left: 30px;
  transition: 1s ;  
}

.hamburger .line{
  width: 40px;
  height: 5px;
  background-color: #D7B56C;
  display: block;
  margin: 7px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
  transition: 1s;
  
}

.hamburger:hover{
  cursor: pointer;
}

#hamburger{
  position: relative;
  right:20px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger.is-active{
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger:before{
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border: 5px solid transparent;
  top: calc(85% - 35px);
  left: calc(85% - 35px);
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger.is-active:before{
  border: 5px solid #D7B56C; 

}

#hamburger.is-active .line{
  width: 25px;
  background-color: #D7B56C; 
}

#hamburger.is-active .line:nth-child(2){
  opacity: 0;
  background-color: #D7B56C;

}

#hamburger.is-active .line:nth-child(1){
  -webkit-transform: translateY(13px);
  -ms-transform: translateY(13px);
  -o-transform: translateY(13px);
  transform: translateY(13px);
  background-color: #D7B56C;

}

#hamburger.is-active .line:nth-child(3){
  -webkit-transform: translateY(-13px) rotate(90deg);
  -ms-transform: translateY(-13px) rotate(90deg);
  -o-transform: translateY(-13px) rotate(90deg);
  transform: translateY(-11px) rotate(90deg);
  background-color: #D7B56C;

}
.ourAmenities{
  position: relative;
  left: 100% !important;
}
/* @media(max-width:1890px){
  .titleSize{
    font-size: 150px;
  }
  .ourAmenities{
    position: relative;
    left: 130% !important;
  }
} */
@media(max-width:1590px){
  .titleSize{
    font-size: 150px;
  }
  .ourAmenities{
    position: relative;
    left: 120% !important;
  }
}
@media(max-width:1090px){
  .titleSize{
    font-size: 120px;
  }
  .ourAmenities{
    position: relative;
    left: 200% !important;
  }
}
@media(max-width:890px){
  .titleSize{
    font-size: 100px;
  }
  .whyUsText{
    font-size: 250px;
   }
   .ourAmenities{
    position: relative;
    left: 250% !important;
  }
}
@media(max-width:750px){
  .titleSize{
    font-size: 80px;
  }
  .whyUsText{
    font-size: 150px;
   }
   .ourAmenities{
    position: relative;
    left:220% !important;

  }
}

@media(max-width:550px){

  .whyUsText{
    font-size: 150px;
   }
   .ourAmenities{
    position: relative;
    left: 450% !important;
    font-size: 65px;
  }
  .titleSize{
    font-size: 70px;
  }
}