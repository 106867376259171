.downCircle{
    animation: App-logo-spin infinite 8s linear;
}
.waveText{
  text-decoration-style: wavy;
  text-decoration-color: white;
  text-decoration-line: underline;
  text-underline-offset: 15px;
  text-decoration-thickness: 3px;  
}
.waveAnimation{
  text-decoration-style: wavy;
  text-decoration-color: black;
  text-decoration-line: underline;
  text-underline-offset: 15px;
  text-decoration-thickness: 3px;  
}
.waveline{
 clip-path: inset(0 100% 0 0); 
  transition: .5s;
}
.waveline.active{
  clip-path: inset(0 0 0 0); 
  transition: 2s;

} 
.waveline2{
  clip-path: inset(0 100% 0 0); 
   transition: .5s;
 }
 .waveline2.active2{
   clip-path: inset(0 0 0 0); 
   transition: 2s;
 
 } 
 .homepMainPc{
  display: block;
  position: relative;
  height: 90vh;
 }
 .homepMainMb{
  display: none;
 }
 
.homep1{
  width: 330px;
  position: absolute;
  left: 5%;
}
.homep2{
  width: 340px;
  position: absolute;
  left: 40%;
  top: 10%;
}
.homep3{
  width: 330px;
  position: absolute;
  right: 5%;
}
.homep4{
  width: 205px;
  position: absolute;
  left: 5%;
  top: 40%;
}
.homep5{
  width: 255px;
  position: absolute; 
  left: 25%;
  top: 40%;
}
.homep6{
  width: 232px;
  position: absolute; 
  left: 50%;
  top: 40%;
}
.homep7{
  width: 250px;
  position: absolute; 
  left: 70%;
  top: 40%;
}


.swingCons {
  animation: swing ease-in-out 2s   alternate;
  transform-origin: center -20px;
  float: left;
}


  .ourAmenitiesPc{
    display: none;
   }
   .ourAmenitiesMb{
    display: block;
   }

  .ourAmeMain{
    width: 100%; 
    height: 400px;
    background: #A5AFA0;
    transition: .5s;
    border-radius: 60px; 
  } 

  .ourAme{
    width: 100%; 
    height: 400px;
    border-radius: 60px;
    transition: .5s;
    background-repeat:'no-repeat';
    background-size:'cover';
    display: flex;
    align-items: center;
    justify-content: center;
   
  }  
  .ourAme:hover   {
    border-radius: 30px;    
  }
  .ourAmeBtn{
   display: none;
   transition: 2s;
   
  }
  .ourAme:hover  .ourAmeBtn{
    display: block;
   transition: 2s; 
   }
  
   
   .whyUsCard{
    flex-shrink: 0;
    border-radius: 25px;
    border: 1px solid #79A633;
    background: rgba(121, 166, 51, 0.20);
    backdrop-filter: blur(9.5px);
    position: absolute; 
   }
   .whyUsMainPc{
    display: block;
   }
   .whyUsMainMb{
    display: none;
   }
   .valuesMain:hover .valuesCircle{
    animation: App-logo-spin infinite 8s linear;
    cursor: pointer;
   }

   .scroll-text {
    white-space: nowrap;
    animation: scrollText 10s linear infinite;
  }
  
  @keyframes scrollText {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }


  .ourAmeniMain{
    position: relative;
    height: 100%; 
    border-radius:60px ;
    transition: .5s;
    height: 400px;
  } 

  .ourAmeni{
    width: 100%;
    height: 100%;
    border-radius:60px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    backface-visibility: hidden; 
    transform: .5s !important;
    background-repeat:'no-repeat';
    background-size:'cover'; 
  }
  .ourAmeniT{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 30%; 
    opacity: 0;
    transition: .5s  ;
    text-align: center;
    border-radius: 60px;
    transform: .5s;

  }
  .ourAmeniMain:hover{
    border-radius: 30px;
    transform: .5s; 
  }
  .ourAmeni:hover{
    cursor: pointer;
    transform: .5s;   
  }
  .ourAmeniMain:hover .waveTextHide{
    opacity: 0;
    transform: .5s;
  }
  .ourAmeniMain:hover .ourAmeni {
    filter: blur(2px); 
    transform: .5s; 
    border-radius: 30px;

  } 
  .ourAmeniMain:hover .ourAmeniT {
    opacity: 2; 
    border-radius: 30px;
    transform: .5s;
    cursor: pointer;

  }
  .parallax{
    background: url('./images/leavesBg.png')  fixed 10%;
    background-size: 150px 150px;
    color: aliceblue;
    background-repeat: no-repeat;
}
.chikuOrch{
  background: url('./images/leaves1.png') fixed ,url('./images/leaves2.png') fixed  ;
  /* background: url('./images/leaves2.png')  fixed 10%; */
  background-position:  10%  ,90% ;

  background-size: 120px;
  color: aliceblue;
  background-repeat: no-repeat;
}

 

  @media(max-width:990px){
    .homepMainPc{
      position: relative; 
      height: 150vh;
    }
   
    .homep1{
      width: 330px;
      position: absolute;
      left: 5%;
    }
    .homep2{
      width: 340px;
      position: absolute;
      left: 35%;
      top: 25%;
    }
    .homep3{
      width: 330px;
      position: absolute;
      right: 5%;
    }
    .homep4{
      width: 205px;
      position: absolute;
      left: 10%;
      top: 40%;
    }
    .homep5{
      width: 255px;
      position: absolute; 
      left: 40%;
      top: 45%;
    }
    .homep6{
      width: 232px;
      position: absolute; 
      left: 25%;
      top: 70%;
    }
    .homep7{
      width: 250px;
      position: absolute; 
      left: 70%;
      top: 40%;
    } 
    
  }
  @media(max-width:790px){
    .homepMainPc{
      display: none;
     }
    .homepMainMb{
      display: block;
     }
     .ourAmenitiesPc{
      display: none;
     }
     .ourAmenitiesMb{
      display: block;
     }

     .ourAmenitiesMb .ourAme{
      width: 100%; 
      height: 400px;
      border-radius: 60px;
      transition: .5s;
      background-repeat:'no-repeat';
      background-size:'cover';
      display: flex;
      align-items: center;
      justify-content: center;
    }  
    .whyUsMainPc{
      display: none;
    }
    .whyUsMainMb{
      display: block;
    }
  }
  


  
@keyframes swing {
  0% { transform: rotate(5deg); }
  15% { transform: rotate(-5deg); } 
  30% { transform: rotate(4deg); }
  45% { transform: rotate(-3deg); } 
  60% { transform: rotate(2deg); }
  75% { transform: rotate(-2deg); } 
  90% { transform: rotate(1deg); }  
  100% { transform: rotate(0deg); }
}

   
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }